<template>
  <div></div>
</template>

<script>
import { finishImmoConnectConnection } from '@/services/organization'
import { getUrlParam } from '@/utils/helpers'

export default {
  name: 'ImmoConnect',
  created () {
    this.init()
  },
  computed: {
    isSuccess () {
      return this.$route.params.status === 'success'
    }
  },
  methods: {
    async init () {
      await this.finishConnection()
    },
    async finishConnection () {
      let is_success = this.isSuccess
      try {
        const user_api_key = getUrlParam('enduserapikey')
        const user_email = getUrlParam('correlationID')
        const payload = { user_api_key, user_email, is_success }
        const response = await finishImmoConnectConnection(payload)
        is_success = response.data?.success
      } catch (error) {
        console.error(error)
        is_success = false
      } finally {
        this.$router.push({ name: 'Profile', query: { is_success } })
      }
    }
  }
}
</script>
