<template>
  <div>
    <PageHeader :title="'Mijn profiel'" />
    <div class="tw-my-8">
      <div v-if="immoConnectNotification !== null" :class="['tw-mb-4 tw-font-bold', immoConnectNotification.success ? 'tw-text-green-400' : 'tw-text-error']">{{ immoConnectNotification.message }}</div>
      <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-mb-2">
          <div class="tw-flex tw-flex-row tw-gap-2">
            <button :class="['btn', noConnectionRecordFound ? 'btn-success' : 'btn-default']" @click="startConnection">{{ noConnectionRecordFound ? 'Koppelen met Immo-Connect' : 'Opnieuw koppelen met Immo-Connect' }}</button>
          </div>
      </div>

      <div v-if="!noConnectionRecordFound" class="tw-text-xs tw-italic">
        Laatst gekoppeld op {{ immoConnectData.updated_on | datetime }}
        <span v-if="immoConnectData.status != 2" :class="['tw-p-0.5', getStatus(immoConnectData.status).class]">(status: {{ getStatus(immoConnectData.status).label }})</span>
      </div>
    </div>
    <div v-if="preferences" class="tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-4">
      <FormulateForm
          v-model="formData"
          :name="'preferencesForm'"
          debounce
        >
        <div class="tw-px-4 tw-pb-4 tw-rounded tw-shadow-md tw-bg-white">
          <div class="tw-flex tw-justify-between tw-gap-4 tw-items-center">
            <span class="tw-justify-start tw-inline-block tw-mt-4">
              <h2 class="tw-my-0">Voorkeuren</h2>
            </span>
            <span class="tw-justify-end tw-inline-block tw-mt-4 ">
              <button
                v-if="!showForm"
                @click="showPreferencesForm"
                title="Bewerken"
                class="action tw-m-1 tw-bg-success tw-h-full tw-text-xs"
              >
                <i class="fas fa-pencil" />
                <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Bewerken</span>
              </button>
              <button
                v-if="showForm"
                @click="updatePreferences"
                title="Opslaan"
                class="action tw-m-1 tw-bg-success tw-h-full tw-text-xs"
              >
                <i class="fas fa-save" />
                <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Opslaan</span>
              </button>
              <button
                v-if="showForm"
                @click="closePreferencesForm"
                title="Annuleren"
                class="action tw-m-1 tw-bg-error tw-h-full tw-text-xs"
              >
                <i class="fas fa-times" />
                <span class="tw-ml-1 tw-hidden sm:tw-inline-block">Annuleren</span>
              </button>
            </span>
          </div>
          <dl v-if="!showForm" class="tw-my-2.5 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <template>
              <dt>Toon telefoonnummer voor verhuurpanden op website</dt>
              <dd>{{ preferences.show_phone_number_on_website_for_rental_properties | yesno }}</dd>
            </template>
          </dl>
          <div v-if="showForm" class="tw-flex tw-gap-4 tw-flex-wrap">
            <FormulateInput
              type="checkbox"
              :name="'show_phone_number_on_website_for_rental_properties'"
              label="Toon telefoonnummer voor verhuurpanden op website"
            />
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import PageHeader from '@/components/PageHeader.vue'

import { getImmoConnectStatus, getCollaboratorPreferences, startImmoConnectConnection, updateCollaboratorPreferences } from '@/services/organization'
import { mapGetters } from 'vuex'
import { successModal, errorModal } from '@/modalMessages'
import { getUrlParam } from '@/utils/helpers'

export default {
  name: 'Profile',
  components: {
    PageHeader
  },
  data () {
    return {
      immoConnectNotification: null,
      immoConnectData: null,
      preferences: null,
      formData: null,
      showForm: false
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),

    noConnectionRecordFound () {
      return this.immoConnectData === null
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const is_success = getUrlParam('is_success')
      if (is_success) {
        if (is_success === 'true') {
          this.immoConnectNotification = { success: true, message: 'Koppeling gelukt met Immo-Connect' }
        } else {
          this.immoConnectNotification = { success: false, message: 'Koppeling mislukt met Immo-Connect' }
        }
      }
      await this.getImmoConnectStatus()
      await this.getPreferences()
    },
    async startConnection () {
      try {
        const response = await startImmoConnectConnection()
        const immoConnectUrl = response.data?.immoconnect_url
        location.href = immoConnectUrl
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets verkeerd bij het laden van Immo-Connect-pagina')
      }
    },

    async getImmoConnectStatus () {
      try {
        const response = await getImmoConnectStatus()
        const data = response.data
        if (data.status) this.immoConnectData = data
        return response
      } catch (error) {
        errorModal('Er ging iets verkeerd bij ophalen van de Immo-Connect-status')
      }
    },
    async getPreferences () {
      try {
        const response = await getCollaboratorPreferences(this.collaborator.id)
        this.preferences = response.data
        this.formData = cloneDeep(this.preferences)
        return response
      } catch (error) {
        errorModal('Er ging iets verkeerd bij ophalen van je voorkeuren')
      }
    },
    showPreferencesForm () {
      this.showForm = true
    },
    closePreferencesForm () {
      this.showForm = false
      this.formData = cloneDeep(this.preferences)
    },
    async updatePreferences () {
      try {
        const response = await updateCollaboratorPreferences(this.collaborator.id, this.formData)
        await this.getPreferences()
        successModal('Je voorkeuren werden aangepast')
        this.showForm = false
        return response
      } catch (error) {
        errorModal('Er ging iets verkeerd bij aanpassen van je voorkeuren')
      }
    },
    getStatus (status) {
      const mapping = {
        // 1 is pending or interrupted, assume it's interrupted
        // 3 is failed
        1: { label: 'onderbroken', class: 'tw-bg-warning' },
        3: { label: 'mislukt', class: 'tw-bg-error tw-text-white' }
      }
      return mapping[status]
    }
  }
}
</script>
